@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600&display=swap");

html,
body {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.leaflet-container {
  height: 100vh;
}
.leaflet-popup-content {
  margin: 16px;
}
.leaflet-control-attribution {
  display: none !important;
}
.overlay-top_info {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(57, 57, 57, 0.39);
  z-index: 1000;
  padding: 12px 16px;
  color: #fff;
  border-radius: 12px;
}
.overlay-summary_info {
  position: absolute;
  bottom: 1rem;
  right: 0rem;
  left: 0rem;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
}
.summary-info-item {
  cursor: pointer;
  width: 72px;
  text-align: center;
  display: flex;
  padding: 8px;
  flex-direction: column;
}
.summary-info-item.active {
  background-color: #0550ff;
  border-radius: 8px;
  color: #fff;
}
.summary-info-item img {
  width: 26px;
  height: 26px;
  margin-bottom: 8px;
}
.summary-info-item > label {
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  font-family: "Poppins", sans-serif; /* Apply Poppins font */
}
.summary-info-wrapper {
  background-color: #fff;
  padding: 14px 16px;
  margin: 0 16px;
  border-radius: 12px;
  /* width: 400px; */
  display: flex;
  gap: 8px;
}
.summary-info-label {
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins", sans-serif; /* Apply Poppins font */
}
.summary-info-value {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif; /* Apply Poppins font */
}
.service-list,
.route-info {
  max-height: 450px;
  overflow-y: auto;
}
.service-item {
  width: 100%;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}
.service-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.service-item_info {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  /* width: calc(50% - 8px); */
  width: inherit;
}

.service-item_info:last-child {
  margin-bottom: 12px;
}

.separator {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 16px;
}

.service-item_info-left,
.service-item_info-right,
.route-info_section {
  width: calc(50% - 8px);
}

.route-info_section {
  margin-bottom: 6px;
}

.service-item_info-left b,
.service-item_info-right b,
.route-info_section b,
.terminal-info b, .others-info b {
  font-weight: 600;
  font-size: 12px;
}
.service-item_info-left:last-child,
.service-item_info-right:last-child {
  margin-bottom: 8px;
}
.service-item_label {
  background-color: #a3d3ff;
  color: #0550ff;
  padding: 2px 12px;
  border-radius: 4px;
  font-weight: 600;
  width: fit-content;
  font-size: 12px;
}
.service-item_main {
  font-weight: 600;
  font-size: 16px;
  /* margin-bottom: 16px; */
}
.service-item_status {
  font-size: 12px;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.route-info_card {
  padding: 20px 20px;
  border: 1px solid #dadada;
  border-radius: 8px;
}
._btn-custom {
  font-size: 12px !important;
  padding: 6px 16px !important;
  font-weight: 600 !important;
}
.modal-content_custom {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;

  display: flex;
  margin: 0 auto;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.modal-content_custom-body {
  /* width: 100%; */
  background-color: #fff;
  padding: 28px 24px;
  border-radius: 12px;
  width: 600px !important;
}
